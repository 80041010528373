
.modal-title{
  color:black;
}

.card-img-overlay{
color:white
}

#fullScreenImage{
  height: 90vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;  
}

#fullScreenImageCategories
  {
      height: 55vh; 
      width: 35vh; 
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;  
  }

#showNavbarLogoProxy{
  padding:20px;
  
}

#iv{
  padding-top: 40px;
  padding-bottom: 60px;
}

#carousel-home{
  padding-top:150px;
  background-color: #dbdbc8;
}

#footer{
  background-color: #dbdbc8;
}

  .carousel-item{
    text-align:center;
    }

    .carousal-snippet{
      color: #202020;
      padding:100px;
    }

.parallax {
  top:100px;
  position: fixed; 
  left: 50%;
  transform: translate(-50%, 0);
  z-index:0;  
}


.navbar{
background-color: #f7f7f2;
color:#202020;
height:60px;
}


.logo-font {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: 'Sacramento', cursive;
  text-align:center;

}

.logo-intro {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size:1.5rem; 
  text-align:center;
}


.App {
  background-color:#f7f7f2;
  font-family: 'Fira Sans Condensed', sans-serif;
  color: #202020;
  padding-top: 700px;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) { 

  .parallax {
    position:fixed;
    top:70px;
  }
  
  .h4 {
    font-size: 1.1rem; 
  }

  .carousal-snippet{
    padding:30px
  }

  #fullScreenImage{
    height: 60vh; 
  }
  
  #fullScreenImageCategories
  {
    height: 45vh; 
  }
}